<template>
  <div
    @click="handleClick"
    @mousemove="isOnHover = true"
    @mouseleave="isOnHover = false"
    @touchmove="isOnHover = true"
    @hover="isOnHover = true"
  >
    <div class="tw-relative background-card">
      <div v-show="!isOnHover" class="card">
        <div class="tw-top-5 tw-left-5 tw-absolute tw-z-10">
          <UserIcon
            :bgImage="userIcon"
            :userName="influencer"
            :quality="quality"
            avaBorderColor="primary"
          />
        </div>
        <v-img
          class="tw-w-full tw-h-full"
          :lazy-src="getImage('bg-min.jpg')"
          :src="src"
        ></v-img>
      </div>
      <div v-if="isOnHover">
        <MatchMedia>
          <div class="tw-relative">
            <StoryCarousel
              :default-duration="2.5"
              :durations="durations"
              :bgImage="userIcon"
              :influencer="influencer"
              :likes="likes.toString()"
              :experienceName="experienceName"
              :quality="quality"
              :cityName="cityName"
              :country="country"
              @slideChanged="slideChanged"
              class="card-bigger"
              @change="handleClick($event)"
              :item="data"
            >
              <template v-for="(item, i) in items">
                <rl-carousel-slide
                  :key="item"
                  class="w-100 h-100 position-relative"
                >
                  <ImageSlide
                    v-if="!item.includes('.mp4')"
                    ref="slides"
                    :item="item"
                    :index="i"
                  />
                  <VideoSlide
                    ref="slides"
                    v-if="item.includes('.mp4')"
                    :item="item"
                    :index="i"
                    @duration-changed="d => durationChanged(i, d)"
                  />
                </rl-carousel-slide>
              </template>
            </StoryCarousel>
          </div>
        </MatchMedia>
      </div>
    </div>
  </div>
</template>

<script>
import StoryCarousel from './ItemCarousel.vue';
import ImageSlide from './ImageSlide.vue';
import VideoSlide from './VideoSlide.vue';
import { RlCarouselSlide } from 'vue-renderless-carousel';
import { MatchMedia } from 'vue-component-media-queries';
import UserIcon from './UserIcon.vue';
import { transformImage } from '@/utils/transformImage';

export default {
  components: {
    VideoSlide,
    ImageSlide,
    StoryCarousel,
    RlCarouselSlide,
    MatchMedia,
    UserIcon
  },
  props: {
    items: {
      type: Array,
      default: () => []
    },
    bgImage: String,
    influencer: String,
    likes: String || Number,
    experienceName: String,
    cityName: String,
    country: String,
    data: Object,
    quality: Number,
    coverImage: String || null
  },
  data: function() {
    return {
      isOnHover: false,
      cardHover: 'cardHover',
      card: 'card',
      mediaLoaded: false
    };
  },
  computed: {
    userIcon: {
      get() {
        return transformImage(this.bgImage, 200);
      }
    },
    durations() {
      const d = {};

      this.items.forEach((item, index) => {
        if (item.includes('.mp4') && item.duration) {
          d[index] = item.duration;
        }
      });

      return d;
    },
    src() {
      return this.coverImage !== 'NULL'
        ? transformImage(this.coverImage)
        : this.bgImage;
    }
  },
  methods: {
    durationChanged(index, duration) {
      this.durations[index] = duration;
    },
    slideChanged(index) {
      this.$refs.slides.forEach((slide, i) => {
        if (i === index) {
          if (typeof slide.show === 'function') {
            slide.show();
          }
        } else {
          if (typeof slide.hide === 'function') {
            slide.hide();
          }
        }
      });
    },
    getImage: name => require('@/assets/destinationPage/loader/' + name),
    handleClick(data) {
      this.$emit('click', data);
    }
  }
};
</script>

<style lang="scss" scoped>
$width: 19vw;
$deltaHeigth: 7vw;

.story {
  .slide {
    height: 100%;
    width: 100%;
    background-size: cover;
  }
}

.background-card {
  width: 13.95vw;
  height: 16.35vw;
}

.card {
  width: 13.95vw;
  height: 16.35vw;
  border-radius: 1.77vw !important;
  overflow: hidden;
  position: relative;
  cursor: pointer;
}

.card-bigger {
  border-radius: 1.77vw;
  overflow: hidden;
}

@media (max-width: 770px) and (min-width: 500px) {
  .cardHover {
    width: 16.35vw;
    height: 27vw;
    top: -2.6vw;
    border-radius: 1.77vw;
    overflow: hidden;
    position: relative;
    cursor: pointer;
  }
}
</style>
