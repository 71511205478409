<template>
  <rl-carousel
    ref="carousel"
    v-model="slide"
    no-wrap
    @slide-changed="slideChanged"
  >
    <div
      slot-scope="{ wrapperStyles, slides: { count } }"
      class="insta-story"
      @click.stop="click"
    >
      <div class="tw-h-full tw-overflow-hidden tw-absolute tw-inset-0">
        <div v-bind="wrapperStyles" class="w-100 h-100">
          <slot></slot>
        </div>
      </div>

      <div class="timeline">
        <div
          v-for="(item, index) in count"
          :key="index"
          class="slide-indicator"
          @click.stop="
            slide = index;
            clearInterval();
          "
        >
          <div class="progressbar w-100" v-if="slide > index">&nbsp;</div>
          <div
            class="progressbar partial"
            :style="{ 'animation-duration': getDuration(index) + 's' }"
            v-if="slide === index"
          >
            &nbsp;
          </div>
        </div>
      </div>

      <div class="bottom-data">
        <BottomSide
          :bgImage="bgImage"
          :userName="influencer"
          :quality="quality"
          :likes="likes"
          :experienceName="experienceName"
          :cityName="slide === 0 ? cityName : undefined"
          :country="slide === 0 ? country : undefined"
          @click="$emit('change', item)"
        />
      </div>

      <div v-if="slide === 0" class="tw-top-12 tw-left-5 tw-absolute tw-z-10">
        <user-icon
          large
          :bgImage="bgImage"
          :userName="influencer"
          :quality="quality"
          :score="item.score"
          avaBorderColor="primary"
          checked
        />
      </div>

      <div class="tw-absolute tw-top-1/2 tw-left-1 tw-right-1">
        <div class="tw-flex tw-w-full tw-justify-between">
          <div
            @click.stop="prevSlide()"
            class="
              tw-cursor-pointer
              tw-flex tw-items-center tw-justify-center
              hover:tw-opacity-90
              tw-pl-4
            "
          >
            <left-shevron-icon />
          </div>
          <div
            @click.stop="nextSlide()"
            class="
              tw-cursor-pointer
              tw-flex tw-items-center tw-justify-center
              tw-pr-4
              hover:tw-opacity-90
            "
          >
            <right-shevron-icon />
          </div>
        </div>
      </div>
    </div>
  </rl-carousel>
</template>

<script>
import { RlCarousel } from 'vue-renderless-carousel';
import BottomSide from './BottomSide.vue';
import UserIcon from './UserIcon.vue';
import LeftShevronIcon from '@/assets/exploreinfluencers/left-shevron.svg';
import RightShevronIcon from '@/assets/exploreinfluencers/right-shevron.svg';

export default {
  name: 'StoryCarousel',
  components: {
    RlCarousel,
    BottomSide,
    UserIcon,
    LeftShevronIcon,
    RightShevronIcon
  },
  props: {
    defaultDuration: {
      type: Number,
      default: 10
    },
    durations: {
      type: Object,
      default: () => {}
    },
    bgImage: String,
    influencer: String,
    likes: String,
    experienceName: String,
    cityName: String,
    country: String,
    quality: Number,
    item: Object
  },
  data() {
    return {
      slide: 0,
      slideCount: 0,
      interval: undefined
    };
  },
  methods: {
    stopTimer() {
      if (this.interval) {
        clearTimeout(this.interval);
      }
    },
    startTimer() {
      this.interval = setTimeout(() => {
        this.nextSlide();
      }, this.getDuration(this.slide) * 1000);
    },
    click(e) {
      if (e.offsetX < 200) {
        this.prevSlide();
      } else {
        this.nextSlide();
      }
    },
    nextSlide() {
      this.stopTimer();
      if (this.slide === this.$refs.carousel.slideCount - 1) {
        this.slide = 0;
      } else {
        this.slide++;
      }
      this.startTimer();
    },
    prevSlide() {
      this.stopTimer();
      if (this.slide === 0) {
        this.slide = this.$refs.carousel.slideCount - 1;
      } else {
        this.slide--;
      }
      this.startTimer();
    },
    getDuration(index) {
      if (this.durations[index]) {
        return this.durations[index];
      }
      return this.defaultDuration;
    },
    slideChanged(index) {
      this.$emit('slideChanged', index);
    }
  },
  created() {
    this.startTimer();
  },
  beforeDestroy() {
    this.stopTimer();
  }
};
</script>

<style lang="scss" scoped>
$width: 27vw;
$deltaHeigth: 12vw;

.insta-story {
  @apply tw-relative tw-text-white tw-overflow-hidden;
  position: absolute;
  width: 15.80vw;
  height: 27vw;
  z-index: 90;
  top: -10.5vw;
  left: -0.920vw;
  border-radius: 1.25vw !important;
  // overflow: hidden;
  transition: width 1s ease 0s;

  @screen md {
    @apply tw-rounded-lg;
  }

  .timeline {
    @apply tw-absolute tw-flex tw-w-full tw-p-2 lg:tw-top-5;
  }

  .bottom-data {
    position: absolute;
    z-index: 100;
    width: 100%;
    padding: 1vw;
    bottom: 0;
  }

  .slide-indicator {
    background-color: rgba(255, 255, 255, 0.5);
    box-shadow: 0px 0px 2px black;
    margin: 3px;
    height: 2px;
    flex-grow: 1;
    border-radius: 2px;
    overflow: hidden;
    cursor: pointer;
  }

  .progressbar {
    background-color: rgba(255, 255, 255, 1);

    @keyframes progressbar {
      0% {
        width: 0;
      }
      100% {
        width: 100%;
      }
    }

    &.partial {
      width: 0;
      animation: progressbar 2s linear infinite;
    }
  }
}
</style>
