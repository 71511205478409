<template>
  <rl-carousel v-model="slide" @swipe-recognized="clearInterval" align="left">
    <div  slot-scope="{ wrapperStyles, slides: { count } }">
      <div v-if="length > 3 && slide < length - 1" class="right-arrow-container hidden-xs-only">
        <div class="tw-flex justify-space-between">
          <div
            @click="handleClickLeft(count)"
            class="
              arrow tw-cursor-pointer
              tw-rounded-full tw-flex tw-items-center tw-justify-center
              hover:tw-bg-opacity-5
            "
          >
            <v-icon color="black">mdi-chevron-right </v-icon>
          </div>
        </div>
      </div>
      <div v-if="slide > 0" class="left-arrow-container">
        <div class="tw-flex justify-space-between">
          <div
            @click="handleClickRight(count)"
            class="
              tw-mr-8 tw-cursor-pointer arrow
              tw-rounded-full tw-flex tw-items-center tw-justify-center
              hover:tw-bg-opacity-5
            "
          >
            <v-icon color="black">mdi-chevron-left </v-icon>
          </div>
        </div>
      </div>
      <div class="slide-container">
        <div v-bind="wrapperStyles">
          <slot></slot>
        </div>
      </div>
    </div>
  </rl-carousel>
</template>

<script>
import { RlCarousel } from 'vue-renderless-carousel';
export default {
  name: 'bar-carousel',
  components: {
    RlCarousel
  },
  props: [ 'length' ],
  data() {
    return {
      slide: 0,
      interval: undefined
    };
  },
  methods: {
    clearInterval() {
      clearInterval(this.interval);
    },
    handleClickRight() {
      this.slide = this.slide - 1 < 0 ? 0 : this.slide - 1;
    },
    handleClickLeft(count) {
      this.slide = this.slide + 1 >= count ? count : this.slide + 1;
    }
  }
};
</script>

<style scoped>
svg {
  transition: color 0.5s ease;
}

.arrow {
  min-width: 2.5vw;
  height: 2.5vw;
  background: #f1f1f1;
}

.left-arrow-container {
  position: absolute;
  z-index: 100;
  left: -1.25vw;
  width: 2.5vw;
  top: calc(8.17vw - 1.25vw);
}

.right-arrow-container {
  position: absolute;
  z-index: 100;
  right: 0.625vw;
  width: 2.5vw;
  top: calc(8.17vw - 1.25vw);
}

.slide-container {
  width: 100%;
  z-index: 99;
}
</style>
