<template>
  <component
    class="drop-down hidden-xs-only"
    :is="selectType"
    :value="value"
    :items="items"
    :menu-props="{ bottom: true }"
    @input="$emit('input', $event)"
    @change="$emit('change', $event)"
  >
    <template v-slot:append><div></div></template>
  </component>
</template>

<script>
import ArrowIcon from '@/assets/landingPage/arrow-bottom.svg';

export default {
  components: { ArrowIcon },
  props: {
    value: {
      type: String,
      required: true,
    },
    country: {
      type: String,
      default: '',
    },
    autocomplete: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: 'destination',
    },
    items: {
      type: Array,
      required: true,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    selectType() {
      return this.autocomplete ? 'v-autocomplete' : 'v-select';
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/variables.scss";
@import "~@/assets/styles/grid.scss";

.drop-down {
  color: #b0b0b2;
  font-family: MontserratRegular;
  font-size: 1.25vw;
  margin-left: 0.156vw;
  height: 1vw;
  margin: -0.364vw 0 0.1em 0 !important;
  padding: 0 !important;
}
.drop-down::v-deep .v-input__slot {
  // border: none !important;
  border-bottom: 0.002vw solid #b0b0b2;
  box-sizing: content-box;
  top: -0.104vw;
  padding: 0 !important;
  overflow: visible;

  .v-select__slot {
    top: 0.104vw;
  }
}
.drop-down::v-deep .v-input__slot::before,
.drop-down::v-deep .v-input__slot::after {
  border: none !important;
  display: none !important;
}
.drop-down::v-deep .v-select__selection {
  margin: 0 !important;
  display: none;
}
.drop-down::v-deep .v-text-field__details {
  display: none !important;
  cursor: pointer;
}
.drop-down::v-deep .v-input__append-inner {
  margin: 0 !important;
  padding: 0.65em 0 0 0 !important;
  cursor: pointer;
  display: none;
}
.drop-down::v-deep .v-select__selection--comma {
  text-overflow: clip;
}

.drop-down::v-deep input,
.drop-down::v-deep input::placeholder {
  max-height: 1.2em !important;
  font-size: inherit !important;
  line-height: 1 !important;
  letter-spacing: inherit;
  // text-decoration: underline;
  color: #b0b0b2 !important;
  cursor: pointer;
}
.drop-down::v-deep input {
  //padding-bottom: 0.2em;
  width: min-content;
}
@media not all and (min-resolution:.001dpcm)
{ @supports (-webkit-appearance:none) and (stroke-color:transparent) {
.drop-down::v-deep input {
  padding-bottom: 0.5rem;
  padding: unset !important;
}
}
}
</style>
