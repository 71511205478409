<template>
  <MatchMedia v-slot="{ md }">
    <div v-show="show" class="loading-overlay-container hidden-xs-only">
     
         <Logo class="tw-mt-10 tw-ml-20 tw-mb-12 tw-w-72 tw-w-24"/>
       
      
      <div class="tw-flex tw-flex-col tw-justify-center tw-items-center">
        <div class="main-container">

            <template v-if="!md">
              <div class="mobile-container">
                <div class="tw-flex tw-flex-row tw-gap-2">
                  <img :src="getImage('loader_img_1.jpeg')" class="img img1" />
                  <div class="tw-flex tw-flex-col tw-gap-2">
                    <img :src="getImage('loader_img_2.jpeg')" class="img img2" />
                    <div class="tw-flex tw-flex-row tw-gap-2">
                      <img :src="getImage('loader_img_3.jpeg')" class="img img3" />
                      <img :src="getImage('loader_img_4.jpeg')" class="img img4" />
                    </div>
                  </div>
                </div>
                <div class="tw-flex tw-flex-row tw-gap-2">
                  <img :src="getImage('loader_img_5.jpeg')" class="img img5" />
                  <img :src="getImage('loader_img_6.jpeg')" class="img img6" />
                </div>
              </div>
            </template>

            <template v-else>
              <div class="images-block" ref="imagesBlock" :style="{ width: imagesBlockWidth }">
                <div class="imgs-row-1"><img :src="getImage('loader_img_1.jpeg')" /></div>
                <div class="imgs-row-2">
                  <div class="tw-h-1/2">
                    <img :src="getImage('loader_img_2.jpeg')" />
                  </div>
                  <div class="tw-flex tw-flex-row tw-h-1/2 bottom-row">
                    <div class="tw-w-1/2 img-3">
                      <img :src="getImage('loader_img_3.jpeg')" />
                    </div>
                    <div class="tw-w-1/2 img-4">
                      <img :src="getImage('loader_img_4.jpeg')" />
                      <img class="tw-mt-6 img7" :src="getImage('loader_img_7.png')" />
                    </div>
                  </div>
                </div>
                <div class="imgs-row-3">
                  <div class="img-5">
                    <img :src="getImage('loader_img_5.jpeg')" />
                  </div>
                  <div class="img-6">
                    <img :src="getImage('loader_img_6.jpeg')" />
                  </div>
                </div>
              </div>
            </template>

          <div><img :src="getImage('animation.gif')" /></div>
          <div class="loader-info">Please wait as Balloon fashions the top travel destinations and trips for you... </div>
        </div>

      </div>
    </div>
  </MatchMedia>
</template>

<script>
import { MatchMedia } from 'vue-component-media-queries';
import Logo from '@/assets/logo/logo.svg';

export default {
  name: 'LoadingOverlay',
  components: {
    MatchMedia,
    Logo,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    imagesBlockWidth: null,
  }),
  mounted() {
    this.resizeImagesHandler();
    window.addEventListener('resize', this.resizeImagesHandler);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resizeImagesHandler);
  },
  methods: {
    getImage: name => require('@/assets/destinationPage/loader/' + name),
    resizeImagesHandler() {
      const { imagesBlock } = this.$refs;
      if (imagesBlock) {
        const width = imagesBlock.clientHeight * 4.5 / 3;
        this.imagesBlockWidth = width <= window.innerWidth ? width + 'px' : null;
      }
    },
  }
};
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/grid.scss';

.mobile-container {
  @apply tw-flex tw-flex-col tw-mt-4 tw-mb-4 tw-gap-2;
}

.img {
  @apply tw-rounded-xl tw-object-cover; 
}

.img1 {
  width: 150px;
  height: 150px;
}

.img2 {
  width: 200px;
  height: 60px;
}

.img3 {
  width: 100px;
  height: 80px;
}

.img4 {
  width: 95px;
  height: 80px;
}

.img5 {
  width: 250px;
  height: 100px;
}

.img6 {
  width: 100px;
  height: 100px;
}

.loading-overlay-container{
  @apply tw-fixed tw-top-0 tw-right-0 tw-bottom-0 tw-left-0 tw-bg-white;
  z-index: 10000;

  .logo {
    @apply tw-m-4 tw-mb-3 tw-float-left;
  }

  .main-container{
    @apply tw-flex tw-flex-col tw-items-center tw-justify-between tw-w-full tw-p-4;

    .images-block{
      @screen md {
        @apply tw-w-full tw-flex tw-mb-12;
        height: 50vh;
       
      }

      img{
        @apply tw-w-full tw-h-full tw-object-cover tw-rounded-xl;
      
        @screen md {
          @apply tw-rounded-3xl;
        }
      }

      .imgs-row-1 {
        width: 34%;
        margin-right: 20px;
        -webkit-mask-image:-webkit-gradient(linear, left top, left bottom, from(rgba(0,0,0,1)), to(rgba(0,0,0,0)));
        mask-image: linear-gradient(to bottom, rgba(0,0,0,1), rgba(0,0,0,0));
      }

      .imgs-row-2 {
        width: 39%;
        margin-right: 20px;
        
        .bottom-row{
          padding-top: 20px;
          .img-3{
            padding-right: 10px;
            -webkit-mask-image:-webkit-gradient(linear, left top, left bottom, from(rgba(0,0,0,1)), to(rgba(0,0,0,0)));
            mask-image: linear-gradient(to bottom, rgba(0,0,0,1), rgba(0,0,0,0));
          }
          .img-4{
            padding-left: 10px;
            img{
              height: auto;
            }
          }
          .img7 {
             -webkit-mask-image:-webkit-gradient(linear, left top, left bottom, from(rgba(0,0,0,1)), to(rgba(0,0,0,0)));
            mask-image: linear-gradient(to bottom, rgba(0,0,0,1), rgba(0,0,0,0));
          }
        }
      }

      .imgs-row-3 {
        width: 27%;
        .img-5{
          height: 33%;
          padding-bottom: 20px;
        }
        .img-6{
          height: 67%;
          -webkit-mask-image:-webkit-gradient(linear, left top, left bottom, from(rgba(0,0,0,1)), to(rgba(0,0,0,0)));
          mask-image: linear-gradient(to bottom, rgba(0,0,0,1), rgba(0,0,0,0));
        }
      }
    }

    .loader-info{
      @apply tw-mt-2 tw-text-center;
      
      font-family: 'MontserratMedium';
      font-size: 1.2rem;
      color: #747378;
      max-width: 40vw;
      line-height: 28px;
    }
  }
}
</style>
