<template>
  <div class="hidden-xs-only">
    <LoadingOverlay :show="showLoader" />
    <div ref="cityData" class="page-container">
      <div class="list-container tw-pl-20 tw-pt-30">
        <div class="filter-container">
          <div class="origin-title">
            <span>{{ 'From&nbsp;' }}</span>
            <div :style="autoCompleteStyle">
              <TeaserSelect
                class="teaser-select"
                :autocomplete="true"
                v-model="selectedCity"
                :items="cities"
              />
            </div>
            <span>&nbsp;{{ dateText }}</span>
          </div>
          <div class="cta-explore-options">Compare our options for you</div>
          <div class="filter-list">
            <div
              :class="{ active: activeFilter === filters.DATE }"
              class="filter-item"
            >
              <div class="label" @click="toggleDateFilter">Dates</div>
              <div v-if="activeFilter === filters.DATE" class="date-filter">
                <div class="filter-content">
                  <v-date-picker
                    @click.prevent
                    :weekday-format="formatWeekday"
                    first-day-of-week="6"
                    class="date-picker"
                    range
                    v-model="dateRange"
                  ></v-date-picker>
                </div>
                <div class="filter-dialog">
                  <div @click="resetDateRange" class="clear">Clear</div>
                  <div @click="applyDateRange" class="save">Save</div>
                </div>
              </div>
            </div>
            <div
              :class="{ active: activeFilter === filters.PRICE }"
              class="filter-item"
            >
              <div class="label" @click="togglePriceFilter">Price</div>
              <div v-if="activeFilter === filters.PRICE" class="price-filter">
                <div class="filter-content">
                  <div class="title">Price</div>
                  <div class="average">The average nightly price is 139€</div>
                  <v-range-slider
                    v-model="priceFilterRange"
                    :max="10000"
                    :min="0"
                    height="60"
                    thumb-label="always"
                    hide-details
                  >
                    <template v-slot:thumb-label="props">
                      <div class="label">{{ props.value }} €</div>
                    </template>
                  </v-range-slider>
                  <div class="input-container">
                    <v-text-field
                      label="Min Price"
                      filled
                      v-model="priceFilterRange[0]"
                      :value="`$ ${priceFilterRange[0]}`"
                    ></v-text-field>
                    <div class="dash"></div>
                    <v-text-field
                      label="Max Price"
                      filled
                      v-model="priceFilterRange[1]"
                      :value="`$ ${priceFilterRange[1]}`"
                    ></v-text-field>
                  </div>
                </div>
                <div class="filter-dialog">
                  <div @click="resetPriceRange" class="clear">Clear</div>
                  <div @click="togglePriceFilter" class="save">Save</div>
                </div>
              </div>
            </div>
            <div
              :class="{ active: activeFilter === filters.INTERESTS }"
              class="filter-item"
            >
              <div class="label" @click="toggleInterestsFilter">Interests</div>
              <div v-if="selectedInterests.length > 0" class="interests-badge">
                {{ selectedInterests.length }}
              </div>
              <div
                v-if="activeFilter === filters.INTERESTS"
                class="interest-filter"
              >
                <div class="filter-content">
                  <div class="title">Interests</div>
                  <v-checkbox
                    v-for="(interest, index) in interests"
                    :key="'interest' + index"
                    v-model="selectedInterests"
                    :label="interest"
                    :value="interest"
                  ></v-checkbox>
                </div>
                <div class="filter-dialog">
                  <div @click="clearInterests" class="clear">Clear</div>
                  <div @click="searchByInterest" class="save">Save</div>
                </div>
              </div>
            </div>
            <div
              :class="{ active: activeFilter === filters.SEASONS }"
              class="filter-item"
            >
              <div class="label" @click="toggleSeasonFilter">Season</div>
              <div
                v-if="selectedSeason === 'Winter Escape'"
                class="winter-badge"
              ></div>
              <div
                v-if="selectedSeason === 'Follow The Sun'"
                class="summer-badge"
              ></div>
              <div
                v-if="activeFilter === filters.SEASONS"
                class="season-filter"
              >
                <div class="filter-content">
                  <div class="title">Season</div>
                  <v-radio-group v-model="selectedSeason">
                    <v-radio
                      v-for="(season, index) in seasons"
                      :key="'season' + index"
                      :label="season"
                      :value="season"
                    ></v-radio
                  ></v-radio-group>
                </div>
                <div class="filter-dialog">
                  <div @click="clearSeasons" class="clear">Clear</div>
                  <div @click="searchBySeason" class="save">Save</div>
                </div>
              </div>
            </div>
            <!-- <div :class="{ active: winterFilter }" class="filter-item">
              <div class="label" @click="setSeasonToWinter">Winter</div>
              <div class="winter-badge"></div>
            </div> -->
          </div>
        </div>
        <div
          :key="'details' + city.city_id + index"
          @mouseenter="() => showCityDetails(index)"
          @hover="() => showCityDetails(index)"
          @click="() => showCityDetails(index)"
          v-for="(city, index) in displayCities"
          :ref="city.city_id"
        >
          <city-section
            :active="index === activeCityIndex"
            :city="city"
            :maxPrice="maxAvailablePrice"
            :minPrice="minAvailablePrice"
            :minFlightPrice="
              typeof cityMinimumFlightPrices[index] === 'number'
                ? cityMinimumFlightPrices[index]
                : null
            "
            :flightDuration="cityMinimumFlightDuration[index]"
          />
        </div>
      </div>

      <div class="map-container">
        <gmap-map
          style="width: 100%; height: 100%"
          :options="mapOption"
          :center="initialCityCoordinates"
          ref="map"
        >
          <gmap-custom-marker
            v-for="(city, index) in displayCities"
            :key="'marker' + city.city_id + index"
            :marker="{ lat: city.coordinates_lat, lng: city.coordinates_lng }"
            alignment="top"
            :z-index="index === activeCityIndex ? 100 : 1"
          >
            <div
              class="city-card-container"
              :class="{ 'icon-mode': index !== activeCityIndex }"
            >
              <div v-if="index === cardCityIndex" class="card-container">
                <img
                  class="city-background"
                  :src="city.background_image"
                  :alt="city.name"
                />
                <div class="city-data-container">
                  <div class="city-country-name">
                    {{ city.name }}, {{ city.country }}
                  </div>
                  <div
                    v-if="
                      typeof cityMinimumFlightDuration[index] === 'number' &&
                      cityMinimumFlightDuration[index] !== Infinity &&
                      cityMinimumFlightDuration[index] !== 0
                    "
                    class="city-flight-duration"
                  >
                    {{ Math.round(cityMinimumFlightDuration[index] / 3600) }}
                    Hour Flight
                  </div>
                  <div
                    v-if="
                      typeof cityMinimumFlightPrices[index] === 'number' &&
                      cityMinimumFlightPrices[index] !== Infinity &&
                      cityMinimumFlightPrices[index] !== 0
                    "
                    class="details-container"
                  >
                    <div class="flight icon"></div>
                    <div class="price-details">
                      Starting from {{ cityMinimumFlightPrices[index] }}€
                    </div>
                  </div>
                  <div
                    v-if="
                      city.experiences
                        .map(experience =>
                          experience.hotels.map(hotel => hotel.price)
                        )
                        .flat()[0] &&
                      city.experiences
                        .map(experience =>
                          experience.hotels.map(hotel => hotel.price)
                        )
                        .flat()[0] !== Infinity &&
                      city.experiences
                        .map(experience =>
                          experience.hotels.map(hotel => hotel.price)
                        )
                        .flat()[0] !== 0
                    "
                    class="details-container"
                  >
                    <div class="hotel icon"></div>
                    <div class="price-details">
                      Starting from
                      {{
                        city.experiences
                          .map(experience =>
                            experience.hotels.map(hotel => hotel.price)
                          )
                          .flat()[0]
                      }}€/night
                    </div>
                  </div>
                  <div
                    v-if="
                      city.experiences
                        .map(experience =>
                          experience.activities.map(activity => activity.price)
                        )
                        .flat()[0] > 0
                    "
                    class="details-container"
                  >
                    <div class="activity icon"></div>
                    <div class="price-details">
                      Starting from
                      {{
                        city.experiences
                          .map(experience =>
                            experience.activities.map(
                              activity => activity.price
                            )
                          )
                          .flat()[0]
                      }}€/person
                    </div>
                  </div>
                </div>
                <div class="book-now-container">
                  <v-btn
                    @click.native="handleBookCity(city.name, 'Booking')"
                    class="book-now-button"
                    >Book Now</v-btn
                  >
                  <div class="caret" />
                </div>
              </div>
              <div class="icon-container">
                <v-img
                  @click.native="() => scrollToCity(city.city_id, index)"
                  class="marker"
                  :src="
                    require(`../../assets/destinationPage/balloon-icon${
                      index !== activeCityIndex ? '' : '-white'
                    }.png`)
                  "
                />
              </div>
            </div>
          </gmap-custom-marker>
        </gmap-map>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import GmapCustomMarker from 'vue2-gmap-custom-marker';
import axios from 'axios';
import LoadingOverlay from '@/views/components/LoadingOverlay.vue';
import CitySection from '@/views/destinationFeed/CitySection';
import TeaserSelect from './TeaserSelect.vue';
import cities from '@/assets/cities.json';
import interests from '@/assets/interests.json';
// import { searchFlightsApi } from '@/api/flights';
import Vue from 'vue';

export default {
  name: 'DestinationFeed',
  components: {
    CitySection,
    LoadingOverlay,
    GmapCustomMarker,
    TeaserSelect,
  },
  data: function() {
    return {
      mapOption: {
        mapId: '6a1cdf7cb1036aae',
        maxZoom: 10,
        zoom: 4.5,
        minZoom: 4.5,
        clickableIcons: false,
        disableDefaultUI: true,
        keyboardShortcuts: false,
        gestureHandling: 'greedy',
      },
      loadedExperiences: [],
      imagesLoaded: false,
      loaderTimeOut: false,
      loaderTimer: null,
      cities: cities.map(city => ({ text: city, value: city })),
      interests: interests,
      seasons: [ 'Winter Escape', 'Follow The Sun' ],
      selectedCity: '',
      cityCoordinates: {},
      cityCoordinatesLoaded: false,
      originCoordinates: { lat: 0, lng: 0 },
      originCityCountry: '',
      activeCityIndex: 0,
      cardCityIndex: undefined,
      activeFilter: '',
      airports: null,
      filters: {
        DATE: 'DATE',
        PRICE: 'PRICE',
        DISTANCE: 'DISTANCE',
        INTERESTS: 'INTERESTS',
      },
      priceFilterRange: [ 0, 10000 ],
      selectedInterests: [],
      selectedSeason: [],
      dateRange: [],
      cityMinimumFlightPrices: [],
      cityMinimumFlightDuration: [],
      season: this.$route.query.season,
      winterFilter: false,
      summerFilter: false,
      showLoader: true,
      searchedCount: 0,
    };
  },
  computed: {
    ...mapGetters([ 'experiences' ]),
    interestChain() {
      return this.selectedInterests
        .map(interest => this.interests.indexOf(interest) + 1)
        .sort((a, b) => a - b)
        .join('-');
    },
    maxAvailablePrice() {
      return this.priceFilterRange[1];
    },
    minAvailablePrice() {
      return this.priceFilterRange[0];
    },
    autoCompleteStyle() {
      return `width: ${this.selectedCity.length * 1.02}ch;`;
    },
    displayCities() {
      const result = this.loadedExperiences
        .map(city => {
          const filteredExpereincesByPrice = city.experiences.filter(
            experience => {
              const totalHotelsPrice =
                experience.hotels?.reduce(
                  (accumulator, hotel) => accumulator + hotel.price,
                  0
                ) ?? 0;

              if (this.winterFilter)
                return (
                  experience.season === 'winter' &&
                  this.summerFilter == false &&
                  totalHotelsPrice >= this.minAvailablePrice &&
                  totalHotelsPrice <= this.maxAvailablePrice
                );
              if (this.summerFilter)
                return (
                  experience.season === 'summer' &&
                  this.winterFilter == false &&
                  totalHotelsPrice >= this.minAvailablePrice &&
                  totalHotelsPrice <= this.maxAvailablePrice
                );
              return (
                totalHotelsPrice >= this.minAvailablePrice &&
                totalHotelsPrice <= this.maxAvailablePrice
              );
            }
          );
          return {
            ...city,
            experiences: filteredExpereincesByPrice,
          };
        })
        .filter(city => city.experiences.length > 0);
      return result;
    },
    activeCityCoordinates() {
      return this.displayCities.length > 0
        ? {
          lat: this.displayCities[this.activeCityIndex].coordinates_lat + 8,
          lng: this.displayCities[this.activeCityIndex].coordinates_lng ?? 0,
        }
        : {
          lat: 0,
          lng: 0,
        };
    },
    initialCityCoordinates() {
      return this.displayCities.length > 0
        ? {
          lat: this.displayCities[0].coordinates_lat + 8,
          lng: this.displayCities[0].coordinates_lng ?? 0,
        }
        : {
          lat: 0,
          lng: 0,
        };
    },
    dateText() {
      return `• ${
        this.dateRange[0]
          ? new Date(this.dateRange[0]).toLocaleDateString('en-GB', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          })
          : ''
      } - ${
        this.dateRange[1]
          ? new Date(this.dateRange[1]).toLocaleDateString('en-GB', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          })
          : ''
      }`;
    },
  },
  watch: {
    async originCoordinates(val) {
      const response = await axios.get('https://api.skypicker.com/locations', {
        params: {
          type: 'radius',
          lat: val.lat,
          lon: val.lng,
          location_types: 'airport',
          limit: 3,
        },
      });
      this.airports = response.data.locations.map(airport => airport.code);
      this.getCityFlightData(++this.searchedCount);
    },
    displayCities() {
      this.getCityFlightData(++this.searchedCount);
    },
    experiences(val) {
      this.showLoader = true;
      this.startLoaderTimer();
      val.length && this.imagesLoadingHandler(val);
      this.loadedExperiences = [ ...val ];
    },
    selectedCity(val) {
      this.$router.push({ query: { ...this.$route.query, city: val } });
      this.getOriginCityCoordinates();
    },
    dateRange(val) {
      this.$store.commit('search/setParams', {
        from: val[0],
        to: val[1],
      });
      localStorage.dateRange = JSON.stringify(val);
    },
    originCityCountry(val) {
      this.$store.commit('search/setParams', {
        country: val.slice(2),
      });
    },
    activeFilter(val) {
      localStorage.activeFilter = val;
    },
    priceFilterRange(val) {
      localStorage.priceFilterRange = JSON.stringify(val);
    },
    winterFilter(val) {
      localStorage.winterFilter = JSON.stringify(val);
    },
    summerFilter(val) {
      localStorage.summweFilter = JSON.stringify(val);
    },
  },
  async created() {
    this.$route.query.city
      ? (this.selectedCity = this.$route.query.city)
      : await axios.get('https://geolocation-db.com/json/').then(response => {
        this.selectedCity = response?.data?.city ?? 'Berlin';
        console.log('city', this.selectedCity);
      });
    if (this.$route.query) {
      this.$store.commit('search/setParams', {
        ...this.$route.query,
      });
    }
    if (this.$route.query.season == 'winter') {
      this.activeWinterFilter();
    }
    if (this.$route.query.season == 'summer') {
      this.activeSummerFilter();
    }

    const startDate = new Date();
    startDate.setDate(startDate.getDate() + 30);

    const endDate = new Date();
    endDate.setDate(endDate.getDate() + 44);

    this.dateRange = [
      startDate.toISOString().slice(0, 10),
      endDate.toISOString().slice(0, 10),
    ];
    if (this.$route.query?.interests?.length > 0) {
      this.selectedInterests = this.$route.query.interests
        .split('-')
        .map(i => this.interests[i - 1]);
      this.loadExperiences(this.$route.query.interests);
    } else {
      this.loadCities();
    }
    // await this.getCityCoordinates();
    // await this.getAllCityCoordinates();
    this.getOriginCityCoordinates();
    await this.$gmapApiPromiseLazy();
  },
  mounted() {
    if (localStorage.activeFilter)
      this.activeFilter = localStorage.activeFilter;
    if (localStorage.priceFilterRange)
      this.priceFilterRange = JSON.parse(localStorage.priceFilterRange);
    if (localStorage.dateRange)
      this.dateRange = JSON.parse(localStorage.dateRange);
    //if (localStorage.winterFilter) this.winterFilter = JSON.parse(localStorage.winterFilter);
  },
  beforeDestroy() {
    this.stopLoaderTimer();
  },
  methods: {
    ...mapActions([ 'loadExperiences', 'loadCities' ]),
    async getAllCityCoordinates() {
      const cityData = await axios.get(
        'https://travelo-91156-default-rtdb.europe-west1.firebasedatabase.app/destination_cities.json'
      );
      this.cityCoordinates = cityData.data;
    },
    handleBookCity(name, mode) {
      this.$router.push({
        path: '/destination/' + name,
        query: { mode, ...this.$route.query },
      });
    },
    showCityDetails(index) {
      this.activeCityIndex = index;
      this.cardCityIndex = undefined;
      this.$refs.map.panTo({
        lat: this.displayCities[index].coordinates_lat,
        lng: this.displayCities[index].coordinates_lng ?? 0,
      });
    },
    scrollToCity(cityId, index) {
      const element = this.$refs[cityId][0];
      element.scrollIntoView({ behavior: 'smooth', block: 'center' });

      this.activeCityIndex = index;
      this.cardCityIndex = index;
    },
    resetDateRange() {
      const startDate = new Date();
      startDate.setDate(startDate.getDate() + 30);

      const endDate = new Date();
      endDate.setDate(endDate.getDate() + 44);

      this.dateRange = [
        startDate.toISOString().slice(0, 10),
        endDate.toISOString().slice(0, 10),
      ];
      this.activeFilter = '';
      this.getCityFlightData(++this.searchedCount);
    },
    applyDateRange() {
      this.activeFilter = '';
      this.getCityFlightData(++this.searchedCount);
    },
    resetPriceRange() {
      this.priceFilterRange = [ 0, 10000 ];
      this.togglePriceFilter();
    },
    async searchByInterest() {
      this.activeFilter = '';
      this.$router.push({
        query: { ...this.$route.query, interests: this.interestChain },
      });
      // this.interestChain.length > 0
      //   ? this.loadExperiences(this.interestChain)
      //   : this.loadCities();
      location.reload();
    },
    async clearInterests() {
      this.selectedInterests = [];
      this.activeFilter = '';
      this.$router.push({ query: { ...this.$route.query, interests: '' } });
      // this.loadCities();
      location.reload();
    },
    async searchBySeason() {
      if (this.selectedSeason === 'Winter Escape') {
        this.winterFilter = true;
        this.$router.push({
          query: { ...this.$route.query, season: 'winter' },
        });
      } else {
        this.summerFilter = true;
        this.$router.push({
          query: { ...this.$route.query, season: 'summer' },
        });
      }
      location.reload();
    },
    async clearSeasons() {
      this.selectedSeason = [];
      this.activeFilter = '';
      (this.winterFilter = false),
      (this.summerFilter = false),
      this.$router.push({ query: { ...this.$route.query, season: '' } });
      // this.loadCities();
      location.reload();
    },

    async activeWinterFilter() {
      this.winterFilter = true;
      this.summerFilter = false;
    },
    async activeSummerFilter() {
      this.summerFilter = true;
      this.winterFilter = false;
    },
    async setSeasonToWinter() {
      this.winterFilter = !this.winterFilter;
      this.$router.push({
        query: { ...this.$route.query, winter: this.winterFilter },
      });
    },
    formatWeekday(date) {
      const day = new Date(date);
      const index = new Date(day).getDay();
      var options = [ 'Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat' ];
      return options[index];
    },
    toggleDateFilter() {
      if (this.activeFilter === this.filters.DATE) {
        this.activeFilter = '';
        return;
      }
      this.activeFilter = this.filters.DATE;
    },
    togglePriceFilter() {
      if (this.activeFilter === this.filters.PRICE) {
        this.activeFilter = '';
        return;
      }
      this.activeFilter = this.filters.PRICE;
    },
    toggleDistanceFilter() {
      if (this.activeFilter === this.filters.DISTANCE) {
        this.activeFilter = '';
        return;
      }
      this.activeFilter = this.filters.DISTANCE;
    },
    toggleInterestsFilter() {
      if (this.activeFilter === this.filters.INTERESTS) {
        this.activeFilter = '';
        return;
      }
      this.activeFilter = this.filters.INTERESTS;
    },
    toggleSeasonFilter() {
      if (this.activeFilter === this.filters.SEASONS) {
        this.activeFilter = '';
        return;
      }
      this.activeFilter = this.filters.SEASONS;
    },
    rebuildFullPage() {
      this.$nextTick(function() {
        this.$refs.fullpage.api.reBuild();
      });
    },
    getOriginCityCoordinates() {
      const addressObject = {
        address_line_1: '',
        address_line_2: '',
        city: this.selectedCity,
        state: '',
        zip_code: '',
        country: '',
      };
      this.$geocoder.send(addressObject, response => {
        this.originCoordinates = response?.results[0]?.geometry.location;
        this.originCityCountry = response?.results[0]?.formatted_address.split(
          ', '
        )[1]
          ? `, ${response?.results[0]?.formatted_address.split(', ')[1]}`
          : '';
      });
    },
    getCityCoordinates() {
      this.displayCities.map(experience => {
        const addressObject = {
          address_line_1: '',
          address_line_2: '',
          city: experience.name,
          state: '', // province also valid
          zip_code: '', // postal_code also valid
          country: experience.country,
        };
        this.$geocoder.send(addressObject, response => {
          this.cityCoordinates[experience.city_id] = {
            position: response?.results[0]?.geometry.location,
            name: response?.results[0]?.formatted_address,
          };
        });
      });
    },
    getCityFlightData(searchIndex) {
     
      this.cityMinimumFlightPrices = this.displayCities.slice();
      this.cityMinimumFlightDuration = this.displayCities.slice();
      this.displayCities.forEach((item, i) => {
        setTimeout(async() => {
          for (let airport of this.airports) {
            if (searchIndex === this.searchedCount) {
              try {
                const response = await axios.get(
                  // 'https://flights.dev.go-travelo.com/api/search',
                  'https://api.tequila.kiwi.com/v2/search',
                  {
                    headers: {
                      apikey: 'ZrsW4-6pv0JOWcXtplHvWs81EoU_HEJ5',
                    },
                    params: {
                      fly_from: airport,
                      fly_to: item.experiences[0].flight_destination,
                      // departure_date: this.dateRange[0],
                      date_from: this.dateRange[0],
                      // return_date: this.dateRange[1],
                      date_to: this.dateRange[1],
                      max_stopovers: 10,
                      max_fly_duration: 50,
                      limit: 1,
                    }
                  }
                );

                if (response.data[0].price[0].price ? true : false) {
                  Vue.set(
                    this.cityMinimumFlightPrices,
                    i,
                    response.data[0].price[0].price
                  );
                  Vue.set(
                    this.cityMinimumFlightDuration,
                    i,
                    response.data[0].duration.departure
                  );

                  break;
                }
              } catch {
                console.log('Couldn\'t load flight price');
              }
            }
          }
        }, 150 * i);
      });
    },
    async imagesLoadingHandler(cities) {
      const loadBackgroundImage = object => {
        return new Promise((resolve, reject) => {
          const src = object.background_image;
          const img = new Image();
          img.onload = () => {
            object.background_image = null;
            this.$nextTick(() => {
              object.background_image = src;
            });
            resolve(object);
          };
          img.onerror = () => {
            object.background_image = null;
            this.$nextTick(() => {
              object.background_image = src;
            });
            reject(object);
          };
          img.src = src;
        });
      };

      const promises = cities
        .map(city => {
          return city.experiences.map(experience =>
            loadBackgroundImage(experience)
          );
        })
        .flat()
        .slice(0, 5);

      return Promise.allSettled(promises)
        .then(() => {
          this.showLoader = false;
        })
        .finally(() => {
          this.showLoader = false;
          console.log(this.showLoader); // => 'not updated'
          this.$nextTick(function() {
            this.showLoader = false;
            console.log(this.showLoader); // => 'updated'
          });
        });
    },
    startLoaderTimer() {
      this.loaderTimer = setTimeout(() => {
        this.showLoader = false;
        this.stopLoaderTimer();
      }, 3000);
    },
    stopLoaderTimer() {
      this.loaderTimer && clearTimeout(this.loaderTimer);
      this.loaderTimer = null;
    },
  },
};
</script>
<style lang="scss" scoped>
.page-container::v-deep {
  display: flex;
  flex-direction: row;
  img {
    object-fit: cover;
  }
  .list-container {
    flex: 6 1 0;
    max-width: 55vw;
    height: calc(100vh);
    overflow: hidden;
    overflow-y: scroll;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    .filter-container {
      padding: 2.5vw 4.5vw 2.1vw 3.1vw;
      margin-bottom: -1.5625vw;
      .origin-title {
        color: #b0b0b2;
        font-family: MontserratRegular;
        font-size: 1.25vw;
        display: flex;
        span {
          width: fit-content;
        }
        .underline {
          text-decoration: underline;
          margin-left: -0.26vw;
        }
      }
      .cta-explore-options {
        color: #28262e;
        font-family: PoppinsSemiBold;
        font-size: 2.5vw;
        margin: 0.83vw 0 0.72vw;
      }
      .filter-list {
        display: flex;
        flex-direction: row;
        margin-top: 1.56vw;
        .active {
          border: 1px solid black;
        }
        .filter-item {
          height: 3.02vw;
          border-radius: 1.5vw;
          margin-right: 1.3vw;
          background-color: #f1f1f1;
          display: flex;
          align-items: center;
          justify-content: center;
          font-family: MontserratRegular;
          font-size: 0.9vw;
          color: #28262e;
          position: relative;
          cursor: pointer;
          box-sizing: border-box;
          -moz-box-sizing: border-box;
          -webkit-box-sizing: border-box;
          .label {
            height: 100%;
            width: 100%;
            padding: 0 2.08vw;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .label::-moz-selection {
            background: none;
          }
          .label::selection {
            background: none;
          }
          .date-filter {
            position: absolute;
            z-index: 150;
            top: 3.12vw;
            left: -2px;
            background: #ffffff;
            box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.06);
            border-radius: 1.041vw;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            overflow: hidden;
            .filter-content {
              .v-picker {
                font-family: PoppinsRegular;
                width: 290px !important;
                background: transparent;
                border: none;
                .v-picker__body {
                  overflow: hidden;
                  border: none;
                }
                .v-date-picker-header {
                  padding: 0 1.458vw;
                  font-size: 0.937vw;
                  margin: 20px 0;
                  .v-btn {
                    background-color: #f2f2f2;
                    height: 1.041vw;
                    width: 1.041vw;
                    .v-btn__content > .v-icon {
                      height: 0.625vw;
                      width: 0.625vw;
                      font-size: 0.625vw;
                      color: black;
                    }
                  }
                }
                .v-date-picker-table {
                  font-family: PoppinsRegular;
                  padding: 0 1.458vw;
                  margin-bottom: 0.735vw;
                  height: 10.73vw;
                  th {
                    font-weight: 500;
                    color: #595959;
                  }
                }
              }
            }
            .filter-dialog {
              padding: 0.625vw 1.25vw 1.25vw;
              border-top: 1px solid #e5e5e5;
              display: flex;
              justify-content: space-between;
              align-items: center;
              font-family: MontserratSemiBold;
              font-size: 0.625vw;
              letter-spacing: -0.02em;
              cursor: pointer;
              .clear {
                color: #747378;
              }
              .save {
                color: white;
                background: #d62a53;
                border-radius: 1.875vw;
                width: 4.01vw;
                height: 1.66vw;
                display: flex;
                justify-content: center;
                align-items: center;
              }
            }
          }
          .price-filter {
            position: absolute;
            z-index: 100;
            width: 360px;
            height: 297px;
            top: 3.12vw;
            left: -2px;
            background: #ffffff;
            border: 1px solid #f1f1f1;
            box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.06);
            border-radius: 20px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .filter-content {
              padding: 24px;
              .title {
                font-family: PoppinsSemiBold;
                font-size: 16px;
                color: #28262e;
                margin-bottom: 6px;
              }
              .average {
                font-family: MontserratRegular;
                font-size: 12px;
                line-height: 16px;
                /* Paragraph */
                color: #747378;
              }
              .v-input--range-slider {
                margin-bottom: 16px;
                .v-slider__track-container {
                  height: 4px;
                }
                .v-slider__thumb-container {
                  .v-slider__thumb {
                    background: white !important;
                    width: 20px;
                    height: 20px;
                    border: 1px solid #d62a53;
                  }
                  .v-slider__thumb::before {
                    display: none;
                  }
                }
                .v-slider__thumb-label-container {
                  top: 44px;
                  left: -12px;
                  background: white;
                  .v-slider__thumb-label {
                    transform: none !important;
                    background: white !important;
                    font-family: MontserratRegular;
                    color: #747378;
                    .label {
                      display: flex;
                      flex-direction: row;
                      width: 60px;
                      padding: 0;
                      background-color: white;
                    }
                    * {
                      transform: none;
                    }
                  }
                }
              }
              .input-container {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                .dash {
                  width: 12px;
                  height: 1px;
                  background: #8e8d92;
                }
                .v-input.v-text-field--filled {
                  max-width: 140px;
                  background: white !important;
                  border-radius: 10px;
                  transform: none;
                  .v-input__control {
                    height: 56px;
                    .v-input__slot {
                      background: white;
                      border: 1px solid #e7e7e7;
                      .v-text-field__slot {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        label.v-label {
                          position: relative !important;
                          top: 20px;
                          font-family: MontserratRegular;
                          font-size: 15px !important;
                          line-height: 12px;
                          font-style: normal;
                          color: #8e8d92;
                        }
                        input {
                          margin-top: 0;
                          padding: 0;
                          font-family: MontserratSemiBold;
                          font-size: 16px;
                          line-height: 14px;
                          color: #28262e;
                        }
                      }
                    }
                    .v-input__slot::before {
                      display: none;
                    }
                    .v-input__slot::after {
                      display: none;
                    }
                  }
                }
              }
            }
            .filter-dialog {
              padding: 12px 24px 24px;
              border-top: 1px solid #e5e5e5;
              display: flex;
              justify-content: space-between;
              align-items: center;
              font-family: MontserratSemiBold;
              font-size: 12px;
              letter-spacing: -0.02em;
              cursor: pointer;
              .clear {
                color: #747378;
              }
              .save {
                color: white;
                background: #d62a53;
                border-radius: 36px;
                width: 77px;
                height: 32px;
                display: flex;
                justify-content: center;
                align-items: center;
              }
            }
          }
          .interests-badge {
            color: white;
            position: absolute;
            background: #d62a53;
            height: 1vw;
            width: 1vw;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 1vw;
            top: 0;
            right: 0;
            font-size: 0.625vw;
          }
          .interest-filter {
            position: absolute;
            z-index: 1000;
            width: 360px;
            min-height: 297px;
            top: 3.12vw;
            left: -2px;
            background: #ffffff;
            border: 1px solid #f1f1f1;
            box-shadow: 0px 0.625vw 1.25vw rgba(0, 0, 0, 0.06);
            border-radius: 20px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .filter-content {
              padding: 1.25vw;
              .title {
                font-family: PoppinsSemiBold;
                font-size: 0.833vw;
                color: #28262e;
                margin-bottom: 0.3125vw;
              }
              .v-input--selection-controls {
                margin-top: 0;
              }
              .v-messages {
                display: none;
              }
              label {
                font-family: MontserratRegular;
                font-size: 0.729vw;
                line-height: 0.729vw;
                letter-spacing: -0.02em;
                color: #28262e;
              }
            }
            .filter-dialog {
              padding: 0.625vw 1.25vw 1.25vw;
              border-top: 1px solid #e5e5e5;
              display: flex;
              justify-content: space-between;
              align-items: center;
              font-family: MontserratSemiBold;
              font-size: 0.625vw;
              letter-spacing: -0.02em;
              cursor: pointer;
              .clear {
                color: #747378;
              }
              .save {
                color: white;
                background: #d62a53;
                border-radius: 1.875vw;
                width: 4.01vw;
                height: 1.66vw;
                display: flex;
                justify-content: center;
                align-items: center;
              }
            }
          }
          .season-filter {
            position: absolute;
            z-index: 1000;
            width: 14vw;
            min-height: 12vw;
            top: 3.12vw;
            left: -2px;
            background: #ffffff;
            border: 1px solid #f1f1f1;
            box-shadow: 0px 0.625vw 1.25vw rgba(0, 0, 0, 0.06);
            border-radius: 20px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .filter-content {
              padding: 1.25vw;
              .title {
                font-family: PoppinsSemiBold;
                font-size: 0.833vw;
                color: #28262e;
                margin-bottom: 0.3125vw;
              }
              .v-input--selection-controls {
                margin-top: 0;
              }
              .v-messages {
                display: none;
              }
              label {
                font-family: MontserratRegular;
                font-size: 0.729vw;
                line-height: 0.729vw;
                letter-spacing: -0.02em;
                color: #28262e;
              }
            }
            .filter-dialog {
              padding: 0.625vw 1.25vw 1.25vw;
              border-top: 1px solid #e5e5e5;
              display: flex;
              justify-content: space-between;
              align-items: center;
              font-family: MontserratSemiBold;
              font-size: 0.625vw;
              letter-spacing: -0.02em;
              cursor: pointer;
              .clear {
                color: #747378;
              }
              .save {
                color: white;
                background: #d62a53;
                border-radius: 1.875vw;
                width: 4.01vw;
                height: 1.66vw;
                display: flex;
                justify-content: center;
                align-items: center;
              }
            }
          }
          .winter-badge {
            position: absolute;
            background-image: url('../../assets/destinationPage/winterIcon.png');
            background-repeat: no-repeat;
            background-size: contain;
            height: 1.3vw;
            width: 1.3vw;
            top: -0.3125vw;
            right: -0.3125vw;
          }
          .summer-badge {
            position: absolute;
            background-image: url('../../assets/destinationPage/summerIcon.png');
            background-repeat: no-repeat;
            background-size: contain;
            height: 1.3vw;
            width: 1.3vw;
            top: -0.3125vw;
            right: -0.3125vw;
          }
        }
      }
    }
  }
  .list-container::-webkit-scrollbar {
    display: none;
  }
  .map-container {
    background-color: #faf2f0;
    flex: 4 1 0;
    height: 100vh;
    bottom: 250px;
    width: 43%;
    right: 0;
    .icon-mode {
      height: 2.96vw;
    }
    .city-card-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      .card-container {
        width: 17.18vw;
        background: white;
        border-radius: 1.04vw;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-bottom: 0.5vw;
        box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.12);
        .city-background {
          height: 10.41vw;
          width: 100%;
          object-fit: cover;
        }
        .city-data-container {
          height: 100%;
          padding: 0.83vw;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          .city-country-name {
            color: #28262e;
            font-size: 0.9375vw;
            font-family: PoppinsSemiBold;
            letter-spacing: -0.02em;
            margin-bottom: 0.3125vw;
          }
          .city-flight-duration {
            color: #747378;
            font-size: 0.729vw;
            font-family: MontserratRegular;
            margin-bottom: 0.83vw;
          }
          .details-container {
            display: flex;
            align-items: center;
            margin-bottom: 0.625vw;
            .icon {
              width: 1.25vw;
              height: 1.25vw;
              background-color: rgba(116, 115, 120, 0.1);
              border-radius: 1.25vw;
              margin-right: 0.416vw;
              filter: brightness(0) saturate(100%) invert(48%) sepia(4%)
                saturate(319%) hue-rotate(212deg) brightness(92%) contrast(96%);
            }
            .flight {
              background-image: url('../../assets/destinationPage/flightSlider.png');
              background-size: 0.625vw;
              background-position: center;
            }
            .hotel {
              background-image: url('../../assets/destinationPage/hotelSlider.png');
              background-size: 0.625vw 11px;
              background-position: center;
            }
            .activity {
              background-image: url('../../assets/destinationPage/activityIcon.png');
              background-size: 0.625vw 0.572vw;
              background-position: center;
            }
            .restaurant {
              background-image: url('../../assets/destinationPage/restaurantIcon.png');
              background-size: 0.52vw 0.625vw;
              background-position: center;
            }
            .price-details {
              color: #747378;
              font-size: 0.729vw;
              font-family: MontserratRegular;
            }
            .rating-badge {
              color: white;
              height: 1.04vw;
              background: #d62a53;
              padding: 0.26vw;
              border-radius: 0.26vw;
              align-items: center;
              justify-content: center;
              display: flex;
              margin-right: 0.416vw;
              font-family: PoppinsSemiBold;
              font-size: 0.625vw;
            }
            .rating-label {
              color: #28262e;
              font-family: PoppinsRegular;
              margin-right: 0.3125vw;
              font-size: 0.729vw;
            }
            .rating-quantity {
              color: #747378;
              font-family: PoppinsRegular;
              margin-right: 0.3125vw;
              font-size: 0.729vw;
            }
          }
        }
        .book-now-container {
          height: 4vw;
          width: 100%;
          border-top: 1px solid rgba(116, 115, 120, 0.15);
          .book-now-button {
            height: 2.18vw;
            width: 15.52vw;
            border-radius: 2.18vw;
            margin: 0.83vw;
            letter-spacing: -0.02em;
            font-size: 0.83vw;
            font-family: MontserratSemiBold;
            color: white;
            background: #d62a53;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .caret {
            height: 0.625vw;
            width: 0.625vw;
            background-color: white;
            position: absolute;
            left: 50%;
            margin-left: -0.3125vw;
            margin-top: -0.4167vw;
            transform: rotate(45deg);
          }
        }
      }
      .icon-container {
        width: 17.18vw;
        .marker {
          height: 3.06vw;
          width: 2.29vw;
          object-fit: contain;
          margin: auto;
          cursor: pointer;
        }
      }
    }
  }
}
.v-btn--is-elevated {
  box-shadow: unset !important;
}
.teaser-select {
  z-index: 150;
}
</style>
