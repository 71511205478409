<template>
  <div :style="{ height: '16.5vw' }">
    <div>
      <carousel :length="experiences.length" class="container hidden-xs-only">
        <rl-carousel-slide
          v-for="(item) in experiences"
          :key="item.description"
          class="slide"
        >
          <carousel-card
            :items="[item.cover_category, ...item.story_array]"
            :bgImage="item.influencer_data.image"
            :coverImage="item.cover_category"
            :influencer="item.influencer_data.influencer_id"
            :likes="item.likes.toString()"
            :experienceName="item.name"
            :data="item"
            :quality="item.quality_score"
            :cityName="city"
            :country="country"
            class="tw-mr-0"
            @click="handleClick(item)"
          />
        </rl-carousel-slide>
      </carousel>
    </div>
  </div>
</template>

<script>
import { RlCarouselSlide } from 'vue-renderless-carousel';

import Carousel from './FeedCarousel.vue';
import CarouselCard from './CarouselCard/MapCarouselCard.vue';

export default {
  name: 'InspirationSlider',
  components: {
    Carousel,
    CarouselCard,
    RlCarouselSlide,
  },
  props: {
    country: String,
    city: String,
    experiences: Array,
  },
  methods: {
    handleClick(item) {
      this.$router.push(`inspiration/${item.exp_id}`);
    },
  },
  computed: {
    optimizeApiSrc() {
      return this.experiences;/*.map(experience => {
        //console.log(experience.influencer_data.image.split('profilepic')[1]);
        //console.log(experience.influencer, experience.quality_score);
        const extension = experience.influencer_data.image.split('profilepic')[1] ? experience.influencer_data.image.split('profilepic')[1].split('?')[0] : '.jpg';
        return (
          experience.influencer_data.image.split('profilepic')[0] +
          'profilepic_200x200' + extension + '?alt=media'
        );
      });*/
    },
  },
};
</script>

<style lang="scss" scoped>
.font-text {
  font-size: 0.98vw;
}

.container {
  display: flex;
  padding: 0;
  width: 100%;
}

.slide {
  height: 16.35vw;
  width: 13.95vw;
  margin-right: 0.98vw;
}
</style>
