<template>
  <div class="tw-items-center tw-flex">
    <div
      class="
        tw-hidden
        lg:tw-block
        tw-w-11 tw-h-11 tw-mr-3
        border
        tw-rounded-full tw-overflow-hidden
      "
      :class="avaClasses"
    >
      <img class="tw-object-cover tw-w-full tw-h-full" :src="img" />
    </div>
    <div>
      <div class="userName" :class="nameClasses">{{ userName }}</div>
      <div v-if="score" class="score" :class="nameClasses">{{ Math.round(score * 100)  }}% trip match</div>
    </div>

    <influencer-checked v-if="quality > 4" class="tw-ml-3 tw-w-6 tw-h-6" />
  </div>
</template>

<script>
import InfluencerChecked from '@/assets/exploreinfluencers/influencer-checked.svg';
import { transformImage } from '@/utils/transformImage';

export default {
  components: {
    InfluencerChecked,
  },
  props: {
    bgImage: String,
    userName: String,
    quality: Number,
    score: Number,
    checked: Boolean,
    large: Boolean,
    color: {
      type: String,
      default: 'white',
    },
    avaBorderColor: {
      type: String,
      default: 'white',
    },
  },
  computed: {
    nameClasses() {
      return `tw-text-${this.color} ${this.large ? 'largeUserName' : ''}`;
    },
    scoreClasses() {
      return `tw-text-${this.color} ${this.large ? 'largeScore' : ''}`;
    },
    img() {
      return transformImage(this.bgImage);
    },
    avaClasses() {
      return `tw-border-${this.avaBorderColor}`;
    },
  },
  methods: {
    getImage: name => require('@/assets/destinationPage/loader/' + name),
  },
};
</script>

<style scoped>
.border {
  border-width: 3.39634px;
  border-style: solid;
}

.influencer {
  font-weight: 600;
}

.userName {
  font-size: 0.83vw;
  font-family: "PoppinsSemiBold";
  text-shadow: 0 0 2px #333;
}

.largeUserName {
  font-size: 1.25vw;
}


.score {
  font-size: 0.625vw;
  font-family: "MontserratRegular";
  text-shadow: 0 0 2px #333;
  margin-top: 0.41vw;
}

.largeScore {
  font-size: 0.83vw;
}

</style>
