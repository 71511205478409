<template>
  <div class="root">
    <div v-if="cityName && country" class="tw-flex tw-mb-3 tw-items-center tw-flex-wrap">
      <v-icon small color="white">mdi-map-marker-outline </v-icon>
      <div class="tw-ml-1 tw-text-sm md:tw-text-base city-name">
        {{ cityName.toUpperCase() }},
      </div>
      <div class="tw-ml-1 city-name">
        {{ country.toUpperCase() }}
      </div>
    </div>
    <div v-if="cityName && country" class="card-title">{{ experienceName }}</div>
    <div class="tw-flex">
      <v-btn 
      class="rounded-pill tw-mt-6 primary--text discoverTrip px-14"
      @click="$emit('click')"
      >Start booking</v-btn>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    bgImage: String,
    userName: String,
    likes: String,
    experienceName: String,
    cityName: String,
    country: String
  },
  components:{
  }
};
</script>

<style scoped>
.root {
  @apply tw-flex tw-flex-col;
}

.red-icon {
  @apply tw-flex tw-justify-center tw-items-center tw-bg-primary tw-w-10 tw-h-10 tw-rounded-full;
}

.card-title {
  font-family: 'PoppinsMedium';
  font-style: normal;
  font-weight: 600;
  letter-spacing: -0.04em;
  text-align: start;
  font-size: 1.4vw;
  line-height: 1.56vw;
}

.likes {
  margin-right: 5px;
  font-family: 'MontserratRegular';
  font-style: normal;
  font-weight: 500;
  font-size: 25.875px;
}

.city-name {
  font-family: 'MontserratSemiBold';
  font-size: 0.57vw;
}

.discoverTrip {
  font-family: 'MontserratBold';
  font-size: 0.67vw;
  letter-spacing: -0.03rem;
  height: 2.3vw !important;
  width: 9.16vw;
  background: white;
}
</style>
