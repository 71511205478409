export function optimizeVideo(src) {
  if (!src) {
    return null;
  }

  return src;

  // let video_id = decodeURIComponent(
  //   src
  //     .split('?alt=media&token=')[0]
  //     .replace(
  //       'https://firebasestorage.googleapis.com/v0/b/travelo-91156.appspot.com/o/',
  //       ''
  //     )
  // );

  // return (
  //   'https://cloudinary-a.akamaihd.net/dimuz209h/video/upload/q_auto/dpr_auto/c_scale,h_1024/f_auto/googleapis/' +
  //   video_id
  // );
}
