<template>
  <div :class="{ hide: displayExperiences.length < 1 }" class="city-section hidden-xs-only">
    <div @click="handleDiscoverCity(city.name)" class="city-title-container">
      <div :class="{ active: active }" class="city-title">
        {{ city.name }}, {{ city.country }}
      </div>
      <div v-if="active" class="discover">Discover City</div>
    </div>
    <div class="city-details-container">
      <div
        v-if="minFlightPrice && minFlightPrice !== Infinity"
        class="city-detail"
      >
        <div class="city-detail-icon flight-icon"></div>
        Starting from {{ minFlightPrice }}€
      </div>
      <div class="city-detail">
        <div class="city-detail-icon hotel-icon"></div>
        Starting from
        {{
          city.experiences
            .map(experience => experience.hotels[0].price)
            .flat()[0]
        }}€/night
      </div>
      <div
        v-if="
          typeof flightDuration === 'number' &&
          flightDuration &&
          flightDuration !== Infinity
        "
        class="city-detail"
      >
        <div class="city-detail-icon clock-icon"></div>
        {{ Math.round(flightDuration / 3600) }}
        {{ Math.round(flightDuration / 3600) !== 1 ? 'hours' : 'hour' }} from
        you
      </div>
    </div>
    <div class="city-experiences-container">
      <inspiration-slider
        :country="city.country"
        :city="city.name"
        :experiences="displayExperiences"
      ></inspiration-slider>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import { transformImage } from '@/utils/transformImage';
import InspirationSlider from './InspirationSlider';

export default {
  name: 'CitySection',
  data() {
    return {
      swiperOption: {
        itemsToShow: 3,
        shortDrag: false,
        mouseDrag: false,
        touchDrag: false,
        wheelControl: false,
      },
      readMore: false,
      covidSlider: this.getBackground('covidSlider.png'),
      flightSlider: this.getBackground('flightSlider.png'),
      hotelSlider: this.getBackground('hotelSlider.png'),
      restaurantSlider: this.getBackground('restaurantSlider.png'),
      weatherSlider: this.getBackground('weatherSlider.png'),
    };
  },
  props: [
    'city',
    'active',
    'minPrice',
    'maxPrice',
    'minFlightPrice',
    'flightDuration',
  ],
  computed: {
    backgroundImage() {
      return {
        backgroundImage:
          'url(' + transformImage(this.city.background_image) + ')',
      };
    },
    isFeed() {
      return this.$route.name === 'DestinationFeed';
    },
    totalActivitiesPricePerExperience() {
      return this.city.experiences.map(experience => {
        return (
          experience.activities?.reduce(
            (accumulator, activity) => accumulator + activity.price,
            0
          ) ?? 0
        );
      });
    },
    totalHotelsPricePerExperience() {
      return this.city.experiences.map(experience => {
        return (
          experience.hotels?.reduce(
            (accumulator, hotel) => accumulator + hotel.price,
            0
          ) ?? 0
        );
      });
    },
    totalRestaurantsPricePerExperience() {
      return this.city.experiences.map(experience => {
        return (
          experience.restaurants?.reduce(
            (accumulator, restaurant) => accumulator + restaurant.price,
            0
          ) ?? 0
        );
      });
    },
    totalPricePerExperience() {
      return this.city.experiences.map(
        (experience, index) =>
          this.totalActivitiesPricePerExperience[index] +
          this.totalHotelsPricePerExperience[index] +
          this.totalRestaurantsPricePerExperience[index]
      );
    },
    displayExperiences() {
      return [ ...this.city.experiences ].sort((experienceA, experienceB) => {
        return experienceB.quality_score - experienceA.quality_score;
      });
    },
  },
  methods: {
    ...mapMutations([ 'setDestinationBackground' ]),
    getBackground: name => ({
      backgroundImage: `url(${require('/src/assets/destinationPage/' + name)})`,
    }),
    toggleReadmore() {
      this.readMore = true;
      this.$emit('readmore-toggled', this.readMore);
    },
    experienceSelectionHandler() {
      this.setDestinationBackground(this.city.background_image);
    },
    handleDiscoverCity(name) {
      this.$router.push({
        path: '/destination/' + name,
        query: { ...this.$route.query, mode: 'Discover' },
      });
    },
  },
  components: {
    InspirationSlider,
  },
};
</script>

<style lang="scss" scoped>
.hide {
  display: none !important;
}

.city-section {
  padding: 2.5vw 2.1vw 2.1vw 3.1vw;
  display: flex;
  flex-direction: column;
  margin-bottom: -2.1vw;

  .city-title-container {
    height: 1.8vw;
    margin-bottom: 1.3vw;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    cursor: pointer;

    .city-title {
      font-family: PoppinsSemiBold;
      margin-right: 0.4vw;
      font-size: 1.6vw;
      color: #28262e;
    }

    .active {
      color: #d62a53;
    }

    .discover {
      font-family: MontserratRegular;
      font-size: 0.9vw;
      color: #d62a53;
    }
  }

  .city-details-container {
    height: 2.5vw;
    display: flex;
    flex-direction: row;
    margin-bottom: 1.67vw;

    .city-detail {
      height: 100%;
      border-radius: 1.25vw;
      border: solid 1px #d7d7d7;
      background-color: #fff;
      padding: 0 1.25vw;
      margin-right: 0.4vw;
      display: flex;
      flex-direction: row;
      align-items: center;
      color: #28262e;
      font-family: MontserratRegular;
      font-size: 0.93vw;
      letter-spacing: -0.03vw;

      .city-detail-icon {
        width: 0.93vw;
        height: 0.93vw;
        margin-right: 0.4vw;
        background-repeat: no-repeat;
        background-size: contain;
        filter: brightness(0) saturate(100%) invert(48%) sepia(4%)
          saturate(319%) hue-rotate(212deg) brightness(92%) contrast(96%);
      }

      .flight-icon {
        background-image: url('../../assets/destinationPage/flightSlider.png');
      }

      .hotel-icon {
        background-image: url('../../assets/destinationPage/hotelSlider.png');
      }

      .clock-icon {
        background-image: url('../../assets/destinationPage/clockIcon.png');
      }
    }
  }

  .city-experiences-container {
    width: 100%;

    .swiper {
      height: 100%;

      .hooper-next {
        background-color: #d7d7d7;
        border-radius: 100px;
      }

      .hooper-prev {
        background-color: #d7d7d7;
        border-radius: 100px;
        margin-left: -1.3vw;
      }

      .hooper-slide > .slide > img {
        width: 13.9vw;
        height: 16.3vw;
      }
    }
  }
}
</style>
