<template>
  <div class="slide">
    <video
      playsinline
      ref="video"
      :src="src"
      preload
      :muted="muted"
      class="video"
      :poster="require('@/assets/destinationPage/loader/bg-min.jpg')"
      @loadeddata="loadedData"
    >
      Your browser does not support the HTML5 Video element.
    </video>
  </div>
</template>

<script>
import { optimizeVideo } from '@/utils/optimizeVideo';
export default {
  name: 'VideoSlide',
  props: {
    item: String,
    index: Number,
    isOnHover: Boolean
  },
  data() {
    return {
      muted: false
    };
  },
  computed: {
    src() {
      return optimizeVideo(this.item);
    }
  },
  methods: {
    show() {
      if (this.$refs.video) {
        this.$refs.video.pause();
        this.$refs.video.currentTime = 0;
        var playPromise = this.$refs.video.play();
        // In browsers that don’t yet support this functionality,
        // playPromise won’t be defined.
        if (playPromise !== undefined) {
          playPromise
            .then(function() {
              // Automatic playback started!
            })
            .catch(_ => {
              // Automatic playback failed.
              // Show a UI element to let the user manually start playback.
              this.muted = true;
              this.$nextTick(() => {
                this.$refs.video.play();
              });
            });
        }
      }
    },
    hide() {
      if (this.$refs.video) {
        this.$refs.video.pause();
      }
    },
    loadedData() {
      if (this.$refs.video) {
        this.$emit('duration-changed', this.$refs.video.duration);
      }
    }
  },
  mounted() {}
};
</script>

<style lang="scss" scoped>
.slide {
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  min-width: 100%;
  min-height: 100%;
  .video {
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    min-width: 100%;
    min-height: 100%;
    object-fit: cover;
  }
}
</style>
