<template>
  <div class="slide" :style="{ 'background-image': `url(${src})`, 'background-color': 'gray' }" />
</template>

<script>
import { transformImage } from '@/utils/transformImage';
export default {
  name: 'ImageSlide',
  props: {
    item: String
  },
  computed: {
    src() {
      return transformImage(this.item, 480);
    }
  }
};
</script>
<style scoped>
.slide {
  width: 16.35vw;
  height: 27vw;
  background-size: cover;
}
</style>
